/* scss/atoms/_selectBlock.scss */

.selectBlock{
    text-align: center;
    margin-bottom: $base;
    select{
        display: inline-block;
        width: auto;
        height: 40px;
        line-height: 40px;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,.18);
    }
}
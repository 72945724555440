/*scss/organisms/_headerMain.scss*/

.siteHeader__nav{
    text-align: center;
    .navMain{
        display: inline-block;
    }
}

.headerMain {
  height: 150px;
}

.headerMain__inner {
  @extend .wrap;
  @extend .grid-row;
  height: 100%;
}

.headerMain__item {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.headerMain__left {
  @include col($four);
}

.headerMain__right {
  @include col($eight);
  text-align: right;
  .navMain {
    display: inline-block;
  }
}

@media only screen and (max-width: 1044px) {
  .headerMain {
    height: auto;
  }
  .headerMain__item {
    top: auto;
    transform: none;
    text-align: center;
  }
  .headerMain__left {
    @include col($twelve);
  }
  .headerMain__right {
    @include col($twelve);
    text-align: center;
    .navMain {
      display: block;
    }
  }
}

.navMain-open{
    .siteHeader__nav{
        text-align: center;
        position: fixed;
        z-index: 20;
        background: rgba(0, 0, 0, 0.8);
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
    }
}





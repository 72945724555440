/* scss/molecules/_listTitle.scss */

.listTitle__list{
    list-style: none;
    padding: 0;
    margin-bottom: $base;
    li{
        margin-bottom: $base/7;
    }
    a{
        color: #660066;
        font-size: 14px;
        &:hover{
            color: #fff;
        }
    }
}

.listTitle__title{
    margin-bottom: $base/4;
}
/* scss/misc/_cleanup.scss */

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body{
    color: #666666;
    font-size: 12px;
    font-family: Trebuchet MS, Tahoma, Verdana;
    background-image: url('../images/cloud_background.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top;
    padding-bottom: 20px;
}
#atomsWrap{
    background: #fff;
}
p{
    line-height: 1.5;
}
img{
  max-width:100%;
}
hr{
  border:1px solid #ccc;
}

a:hover, a:focus{
  text-decoration: none;
}

.google-maps {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.u-noBg{
  background: none !important;
}

$max: 10;
$baseUnit: $base;

@for $i from 1 through ceil($max) {
  $value: ($i - 1) + 1;
  $newVal: $i * $baseUnit;
  .u-mtx#{$i}{
    margin-top: $newVal !important;
  }
  .u-mrx#{$i}{
    margin-right: $newVal !important;
  }
  .u-mbx#{$i}{
    margin-bottom: $newVal !important;
  }
  .u-mlx#{$i}{
    margin-left: $newVal !important;
  }

  .u-ptx#{$i}{
    padding-top: $newVal !important;
  }
  .u-prx#{$i}{
    padding-right: $newVal !important;
  }
  .u-pbx#{$i}{
    padding-bottom: $newVal !important;
  }
  .u-plx#{$i}{
    padding-left: $newVal !important;
  }
}


.u-mbHalf{
  margin-bottom: $base/2 !important;
}

.u-mt0{
  margin-top: 0 !important;
}
.u-mr0{
  margin-right: 0 !important;
}
.u-mb0{
  margin-bottom: 0 !important;
}

.u-ml0{
  margin-left: 0 !important;
}


.u-pt0 {
  padding-top: 0 !important;
}
.u-pr0 {
  padding-right: 0 !important;
}
.u-pb0 {
  padding-bottom: 0 !important;
}
.u-pl0{
  padding-left: 0 !important;
}




.carousel-fade .carousel-inner .item {
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}


.callOutTxt{
  color: red;
  font-weight: bold;
}

.u-txt-underline{
  text-decoration: underline;
}

.u-formNote{
  color: $color1;
  margin-bottom: $base/2;
}

table{
  width: 100%;
}
/* scss/organisms/_legal.scss */

.legal{
    text-align: center;
}

.social{
    text-align: right;
}
@media only screen and (max-width: 1044px) {
    .footerMain{
        .navMain{
            display: none !important;
        }
    }
}

.btns {
  display: inline-block;
  background: #ccc;
  padding: 13px 20px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  transition: all .25s;
  position: relative;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
}

.btns:hover {
  color: #fff;
  text-decoration: none;
}

.navMain__callout{
  a{
    @extend .btns;
    @extend .btn1;
    color: #fff !important;
  }
}

.btn1 {
  background: $color1;
}

.btns-sml {
  background: $color1;
  padding: 8px 15px;
}



.btn2 {
  background: $color2;
}

.btn3 {
  border: 2px solid $color2;
  background: none;
  color: $color2;
  &:hover{
    color: $color2;
  }
}

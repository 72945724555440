/* scss/layout/_callout.scss */

.callout{
    background: #fff;
    margin-bottom: $base;
}
.callout__title{
    text-align: center;
    color: #fff;
    background: $color1;
    padding: 5px 20px;
    text-transform: uppercase;
    letter-spacing: 16px;
    margin-bottom: 0;
}
.callout__inner{
    @extend .grid-row;
    padding: 20px;
}

.callout__item{
    @include col($four);
    &:first-of-type{
        padding-right: 20px;
    }
    &:last-of-type{
        padding-left: 20px;
    }
}
@media only screen and ( max-width: 593px ){ 
    .callout__item{
        @include col($twelve);
        margin-bottom: $base;
        &:first-of-type{
            padding-right: 0;
        }
        &:last-of-type{
            padding-left: 0;
        }
    }   
}

.callout-logos{
    text-align: center;
}

@media only screen and ( max-width: 716px ){ 
    .callout__img{
        display: block;
        margin-bottom: $base/2;
    }
}
/* scss/molecules/_testBlock.scss */

blockquote{
    padding: 0;
    border: none;
    font-size: inherit;
    margin-bottom: $base/2;
}
cite{
    font-style: normal;
}
/* scss/molecules/_formMain.scss */

.formMain {
  padding: 10px 20px;
  .h4 {
    margin-bottom: $base/2;
    margin-top: $base/3;
    font-weight: bold;
  }


}

.formMain-primary {
  background: #f5f5f5;
}

.test{
  display: none;
}

label{
  //font-weight: bold;
}

input[type="text"], input[type="email"], textarea, select{
  @extend .form-input;
}

/*scss/molecules/_navMain.scss*/

.navMain {
  list-style: none;
  padding: 0;
  width: 100%;
  margin-bottom: 0;
  > li {
    display: inline-block;
    text-align: center;
    > a {
      padding: 13px 20px;
      padding-top: 0;
      display: block;
      font-size: 15px;
      text-transform: uppercase;
      color: $color4;
      font-family: $font2;
      z-index: 2;
      position: relative;
    }
  }
}


.dropit-submenu {
  text-align: left;
  width: 100%;
  a {
    padding: 10px;
    display: block;
    background: $color1;
    color: #fff;
  }
}

.dropit-trigger{
  position: relative;
  &:after{
    content: "\f078";
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 5px;
    font: normal normal normal 10px/1 FontAwesome;
  }
}




.navMain_trigger{
  background: $color1;
  color: white;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px !important;
  border-radius: 50%;
  cursor: pointer;
  display: none !important;
  z-index: 2000;
  position: relative;
}



@media only screen and (max-width: 1044px) {
  .navMain_trigger{
    display: inline-block !important;
    position: fixed;
    right: 10px;
    top: 10px;
  }
  .navMain {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 400px;
    z-index: 1000;
    background: $color1;
    background: url('../images/cloud_background-phone.jpg');
    background-size: cover;
    background-position: top center;
    box-shadow: -5px 0px 8px 0px rgba(0,0,0,0.09);
    padding-right: 9px;
    padding-top: 75px;
    transform: translate(110%, 0);
    transition: transform .25s;
    .navMain__callout {
      a {
        text-align: right;
        border-radius: 0;
        background: none;
        color: #fff !important;
        font-weight: bold;
        font-family: $font1;

      }
    }
    > li {
      display: block;
      text-align: right;
      > a{
          color: #fff;
          font-size: 18px;
      }
    }
  }

  .dropit .dropit-submenu {
    position: relative;
    text-align: right;
    margin-bottom: 14px;
    a{
      font-size: 16px;
    }
  }

  .dropit-trigger{
    &:after{
      color: #fff;
    }
  }


  .navMain-open{
    .navMain {
      //opacity: 1;
      transform: translate(0, 0);
      transition: transform .25s;
    }
    .navMain_trigger{
      background: none;
    }
  }




}







/* scss/layout/_main.scss */

.main{
    padding: 12px;
    background: #fff;
    margin-bottom: $base;
}
.main__content{
    @include col($eight);
    padding-right: 12px;
}
.main__aside{
    @include col($four);
    background: #b6e2f9;
    padding: 12px;
}

@media only screen and ( max-width: 976px ){
    .main__content{
        @include col($twelve);
        padding-right: 0;
    }
    .main__aside{
        @include col($twelve);
        padding: 12px;
    }
}
.h1, h1, h2, .h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
  margin-top: 0;
  margin-bottom: $base;
  font-family: $font1;
  color: $color1;
  font-weight: bold;
  line-height: 1;
}

.h1 {
  font-size: 36px;
  @media only screen and ( max-width: 807px ){
    //font-size: 45px;
  }
  @media only screen and ( max-width: 339px ){
    //font-size: 34px;
  }
}

.h2 {
  font-weight: normal;
  font-size: 24px;
  @media only screen and ( max-width: 807px ){
    //font-size: 45px;
  }
  @media only screen and ( max-width: 339px ){
    //font-size: 34px;
  }
}
.h2-alt {
  color: #fff;
}



.h3 {
    color: $color3;
    font-size: 14px;
}

.h4, h4 {
    font-weight: normal;
    font-size: 16px;
    //text-transform: uppercase;
    margin-bottom: $base/4;
}

.h5 {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: $base/2;
  color: $color3;
}
/* scss/animations/_animate.scss */

.downUp{
    position: relative;
    transform: translate(0, 100px) !important;
    transition: all 2s;
    opacity: 0;
}


.leftRight{
    position: relative;
    transform: translate(-100px, 0) !important;
    transition: all 2s;
    opacity: 0;
}

.entered{
    transform: translate(0) !important;
    opacity: 1;
}
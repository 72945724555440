/* scss/molecules/_formGrid.scss */

.formGrid{
	@extend .grid-row;
	@include block-grid(2);
	.formGrid__item{
		@extend .block-grid-item;
		padding: 0;
		position:relative;
	}

	@media (max-width: 715px){ 
		@include block-grid(2);
	}
	@media (max-width: 427px){ 
		@include block-grid(1);
	}
	.formItem__item-full{
        width: 100%;
    }
}

.formGroup-pad{
    padding: 20px;
}

/*scss/variables/_fonts.scss*/

$font1: Trebuchet MS, Tahoma, Verdana;
$font2: Trebuchet MS, Tahoma, Verdana;

.font1{
	font-family: $font1; 
}
.font2{
	font-family: $font2; 
	font-weight: 300;
}

.font{
	span{
		display:block;
	}
}